import { Product } from '@types/product';
import FaqCardGroupType from '~/types/FaqCardGroupType';
import ArticleCardType from '~/types/ArticleCardType';
import VideoContentType from '@types/VideoContentType';
import ImageContentType from '@types/ImageContentType';
import { useRuntimeConfig } from '#app/nuxt';
import { useRoute } from '#app';

const useOrganizationStructuredData = () => {
    const organizationJsonLd = {
        '@context': 'https://schema.org',
        '@type': 'Organization',
        name: 'Roastar',
        url: 'https://www.roastar.com/',
        logo: 'https://www.roastar.com/images/RoastarLogo2022.svg',
        contactPoint: {
            '@type': 'ContactPoint',
            telephone: '866-580-4109',
            contactType: 'sales',
            contactOption: 'TollFree',
            areaServed: 'US',
            availableLanguage: 'en',
        },
        sameAs: [
            'https://www.facebook.com/RoastarBags/',
            'https://x.com/RoastarBags',
            'https://www.instagram.com/roastar/',
            'https://www.youtube.com/RoastarBags',
            'https://www.linkedin.com/company/roastar/',
        ],
    };

    return [
        {
            type: 'application/ld+json',
            innerHTML: JSON.stringify(organizationJsonLd),
        },
    ];
};

const useLocalStructuredData = () => {
    const organizationJsonLd = {
        '@context': 'https://schema.org',
        '@type': 'LocalBusiness',
        name: 'Roastar',
        image: 'https://lh5.googleusercontent.com/p/AF1QipM58ike4OY8ZodOthMItGZ5xEyjiqdfhSmAbECK=s773-k-no',
        '@id': 'https://www.roastar.com/images/RoastarLogo2022.svg',
        url: 'https://www.roastar.com/',
        telephone: '+1866-516-7247',
        description:
            'We make top shelf bags fast and easy. Custom-printed packaging that makes even small businesses look like a really big deal.',
        address: {
            '@type': 'PostalAddress',
            streetAddress: '200 S 84th Ave',
            addressLocality: 'Wausau',
            addressRegion: 'WI',
            postalCode: '54401',
            addressCountry: 'US',
        },
        geo: {
            '@type': 'GeoCoordinates',
            latitude: 44.961071,
            longitude: -89.7439804,
        },
        openingHoursSpecification: {
            '@type': 'OpeningHoursSpecification',
            dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
            opens: '08:00',
            closes: '16:00',
        },
        sameAs: [
            'https://www.facebook.com/RoastarBags/',
            'https://x.com/RoastarBags',
            'https://www.instagram.com/roastar/',
            'https://www.youtube.com/RoastarBags',
            'https://www.linkedin.com/company/roastar/',
        ],
    };

    return [
        {
            type: 'application/ld+json',
            innerHTML: JSON.stringify(organizationJsonLd),
        },
    ];
};

const useProductStructuredData = (product: Product) => {
    const url = `${useRuntimeConfig().public.baseUrl}${useRoute().path}`;
    if (product && product.variations) {
        const productJsonLd = product.variations.map((variant) => {
            return {
                '@context': 'https://schema.org',
                '@type': 'Product',
                '@id': `${url}?variant=${variant.id}`,
                url: `${url}?variant=${variant.id}`,
                name: variant.name,
                sku: variant.sku,
                description: stripHtml(variant.description),
                offers: variant.pricingTiers
                    ? variant.pricingTiers.map((tier) => {
                          return {
                              '@type': 'Offer',
                              price: tier.price,
                              priceCurrency: 'USD',
                              availability: 'https://schema.org/InStock',
                          };
                      })
                    : [],
                brand: {
                    '@type': 'Brand',
                    name: 'Roastar',
                },
                review: [],
                image: [
                    {
                        '@type': 'ImageObject',
                        '@id': `${url}?variant=${variant.id}#primaryimage`,
                        url: variant.image.src,
                        width: '1200',
                        height: '900',
                    },
                ],
            };
        });

        return [
            {
                type: 'application/ld+json',
                innerHTML: JSON.stringify(productJsonLd),
            },
        ];
    } else {
        return [
            {
                type: 'application/ld+json',
                innerHTML: JSON.stringify([]),
            },
        ];
    }
};

const useAlgoliaProductStructuredData = (product: any) => {
    if (product) {
        const productJsonLd = {
            '@context': 'https://schema.org',
            '@type': 'Product',
            '@id': product.slug,
            url: product.slug,
            name: product.name,
            sku: product.sku,
            description: renderRichText(product.description),
            offers: [],
            brand: {
                '@type': 'Brand',
                name: 'Roastar',
            },
            review: [],
            image: [
                {
                    '@type': 'ImageObject',
                    '@id': `${product.slug}#primaryimage`,
                    url: product.image.src,
                    width: '1200',
                    height: '900',
                },
            ],
        };

        return [
            {
                type: 'application/ld+json',
                innerHTML: JSON.stringify(productJsonLd),
            },
        ];
    } else {
        return [
            {
                type: 'application/ld+json',
                innerHTML: JSON.stringify([]),
            },
        ];
    }
};

const useFAQStructuredData = (faqGroups: FaqCardGroupType[]) => {
    const questions = faqGroups.flatMap((group) =>
        group.cards.map((card) => ({
            '@type': 'Question',
            name: `${group.heading}: ${card.title}`,
            acceptedAnswer: {
                '@type': 'Answer',
                text: stripHtml(card.content),
            },
        })),
    );

    const faqJsonLd = {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: questions,
    };

    return [
        {
            type: 'application/ld+json',
            innerHTML: JSON.stringify(faqJsonLd),
        },
    ];
};

const useArticleStructuredData = (articles: ArticleCardType[]) => {
    const url = `${useRuntimeConfig().public.baseUrl}`;
    const articleJsonLd = articles.map((article) => {
        return {
            '@context': 'https://schema.org',
            '@type': 'Article',
            name: article.title,
            headline: article.title,
            author: 'Roastar',
            description: article.description
                ? stripHtml(article.description as string)
                : '',
            url: `${url}${article.slug}`,
            image: article.image
                ? [
                      {
                          '@type': 'ImageObject',
                          url: article.image.src,
                          width: '1200',
                          height: '900',
                      },
                  ]
                : [],
        };
    });

    return [
        {
            type: 'application/ld+json',
            innerHTML: JSON.stringify(articleJsonLd),
        },
    ];
};

const useBreadcrumbStructuredData = (breadcrumbs: any[]) => {
    const breadcrumbItems = breadcrumbs
        ? breadcrumbs.map((breadcrumb, index) => {
              return {
                  '@context': 'https://schema.org',
                  '@type': 'ListItem',
                  position: index + 1,
                  item: {
                      '@id': breadcrumb.link.cached_url,
                      name: breadcrumb.label,
                  },
              };
          })
        : [];

    const breadcrumbJsonLd = {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: breadcrumbItems,
    };

    return [
        {
            type: 'application/ld+json',
            innerHTML: JSON.stringify(breadcrumbJsonLd),
        },
    ];
};

const useImageStructuredData = (image: ImageContentType) => {
    const imageJsonLd = {
        '@context': 'https://schema.org',
        '@type': 'ImageObject',
        name: image.heading,
        description: image.description
            ? stripHtml(image.description as string)
            : '',
        contentUrl: image.image.src,
    };

    return [
        {
            type: 'application/ld+json',
            innerHTML: JSON.stringify(imageJsonLd),
        },
    ];
};

const useVideoStructuredData = (video: VideoContentType) => {
    const videoJsonLd = {
        '@context': 'https://schema.org',
        '@type': 'VideoObject',
        name: video.heading,
        description: video.description
            ? stripHtml(video.description as string)
            : '',
        contentUrl: video.videoUrl,
        thumbnailUrl: video.videoUrl,
        uploadDate: new Date().toDateString(),
    };

    return [
        {
            type: 'application/ld+json',
            innerHTML: JSON.stringify(videoJsonLd),
        },
    ];
};

const stripHtml = (input: string) => {
    if (input) {
        input = input.replace(/<p>/g, '');
        input = input.replace(/<\/p>/g, '\n');
        input = input.replace(/<[^>]*>?/gm, '');
    }

    return input;
};

export {
    useOrganizationStructuredData,
    useLocalStructuredData,
    useProductStructuredData,
    useAlgoliaProductStructuredData,
    useFAQStructuredData,
    useArticleStructuredData,
    useBreadcrumbStructuredData,
    useImageStructuredData,
    useVideoStructuredData,
};
